@import "~carbon-components/scss/globals/scss/styles.scss";
// @import '@carbon/themes/scss/themes.scss';

// @include carbon--theme($carbon--theme--g100);
$charade: #282a37;
$bluebell: rgb(51, 153, 255);

h1, h2, p, ul, li {
  font-family: sans-serif;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}

.donut:empty {
  width: 6rem;
  height: 6rem;
  // margin: 2rem;
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  border-radius: 50%;
  border: 0.3rem solid rgba($bluebell, 0.3);
  border-top-color: $bluebell;
  animation: 1.5s spin infinite linear;

  &.multi {
    border-bottom-color: $bluebell;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// a.bx--tabs__nav-link:focus, a.bx--tabs__nav-link:active {
//   width: 100%;
//   margin: 0;
//   padding-left: 16px;
//   outline: 0px;
//   outline-offset: -2px;
// }

// li.bx--tabs__nav-item--selected {
//   // color: white !important;
  
//   border-bottom: 8px solid #1d76db !important;
//   background-color: #3d3d3d !important;
// }

// li.bx--tabs__nav-item:hover {
//   // color: white !important;
//   border-bottom: 8px solid #1d76db !important;
//   -webkit-transition : border-bottom 300ms ease-out;
//   -moz-transition : border-bottom 300ms ease-out;
//   -o-transition : border-bottom 300ms ease-out;
//   transition : border-bottom 300ms ease-out;
//   background-color: #3d3d3d !important;
// }

// li.bx--tabs__nav-item {
//   // border-bottom: 3px solid #1d76db !important;
//   // color: #565656;
//   // color: white !important;
//   // color: white;
//   text-decoration: none;
//   font-weight: 400;
//   background-color: rgb(24, 22, 22);
// }

// .bx--tabs__nav-item + .bx--tabs__nav-item {
//   margin-left: 0px; 
//   border-left: 1px solid darkgray;
// }

ul.header {
  background-color: #111;
  padding: 0;
  // font-size: 0.875rem;
  // font-weight: 400;
  // line-height: 1.125rem;
  // letter-spacing: 0.16px;
  // color: rgb(24, 22, 22);
  // height: auto;
  // width: auto !important;
  // position: relative;
  // margin: 0px !important;
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
  // border-bottom: 0px !important;
  // padding: 1rem !important;
  // width: 11rem !important;
  // height: 3rem;
  // font-size: larger !important;
  // margin: 0;
  // line-height: inherit;
  // color: white !important;
}
.content {
  background-color: #FFF;
  padding: 20px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
// .content li {
//   margin-bottom: 10px;
// }
// .active {
//   background-color: #0099FF;
// }
// .hover li a {
//   border-bottom: 8px solid #1d76db !important;
//     -webkit-transition : border-bottom 300ms ease-out;
//     -moz-transition : border-bottom 300ms ease-out;
//     -o-transition : border-bottom 300ms ease-out;
//     transition : border-bottom 300ms ease-out;
//     background-color: #3d3d3d !important;

// }




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// body {
//   margin: 0px;
//   overflow: hidden;
// }

.vertical-label {
  -ms-transform: rotate(-90deg); /* IE 9 */
  -webkit-transform: rotate(-90deg); /* Safari prior 9.0 */
  transform: rotate(-90deg); /* Standard syntax */
  margin: 0;
  position: absolute;
  align-self: center;
  top: 60%;
}

.list-css {
  margin-left: 15px;
  padding: 20px;
}

.list-css1 {
  margin-left: 5px;
  padding: 20px;
}

.list-heading {
  font-size:24px;
  color: #3b6caa;
}

.table-list-heading {
  font-weight:bold;
  // color: #3d70b2;
}

.list-subheading {
  font-size:18px;
}

.list-item-css {
  list-style-type:square;
  margin: 5px;
}

.list-item-image-css {
  margin: 2px;
}

.list-image-css {
  height: 18px;
  width: 18px;
  margin-right: 5px;
}

.list-bullet-css {
  height: 9px;
  width: 9px;
  margin-right: 5px;
}

.table-list-item-css {
  // list-style-type:square;
  margin-left: 10px;
}

.list-item-css-level2 {
  list-style-type:disc;
  margin-left: 10px;
}

.table-list-item-css-level2 {
  // list-style-type: disc;
  // list-style-image: url("resources/anaconda.png");
  margin: 10px;
  font-weight: lighter;
  font-size: "13px";
}

.list-text {
  font-size:24px;
  color: #011b45;
}

.tab-div {
  margin-left: 20px;
  position: relative;
  margin-top: 55px;
}

.filter {
  border: 2px solid;
}

.filter-img {
  height: 10px;
  width: 15px;
  margin-left: 2px;
}

.li-img {
  height: 13px;
  width: 17px;
  margin-right: 5px;
}

.li-img--hover {
  height: 13px;
  width: 17px;
  margin-right: 5px;
  filter: drop-shadow(0 0 8px green);
  filter: drop-shadow(0 0 0 8px green);
  -webkit-filter: drop-shadow(0 0 0 8px green);
}

.li-img:hover {
  filter: drop-shadow(0 0 8px green);
  filter: drop-shadow(0 0 0 8px green);
  -webkit-filter: drop-shadow(0 0 0 8px green);
}

.legend1
{
  margin-bottom:0px;
  margin-left:20px;
}

.filter-elements {
  padding:15px;
  display: flex;
  align-items: center;
}

.fieldset1
{
    border:2px solid #011b45;
    // // -moz-border-radius:8px;
    // // -webkit-border-radius:8px;	
    // border-radius:8px;	
}
// text {
//   font-family: sans-serif;
// }

// .tick text {
//   font-size: 2.7em;
//   fill: #635F5D;
// }

// .tick line {
//   stroke: #C0C0BB;
// }

.axis-label {
  font-size: 5em;
  fill: #8e8883;
}

.bx--tabs {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: rgb(24, 22, 22);
  height: auto;
  width: auto !important;
  position: relative;
  margin: 0px !important;
}

a.bx--tabs__nav-link {
  border-bottom: 0px !important;
  padding: 1rem !important;
  width: 11rem !important;
  height: 3rem;
  font-size: larger !important;
  margin: 0;
  line-height: inherit;
  color: white !important;
}

.bx--table-sort {
  background-color: black;
  color: white;
}
.bx--table-sort:hover {
  background-color: black;
  color: white;
  font-weight: bold;
}

.bx--table-sort:focus {
  outline: 0px;
  outline-offset: -2px;
}

a.bx--tabs__nav-link:focus, a.bx--tabs__nav-link:active {
  width: 100%;
  margin: 0;
  padding-left: 16px;
  outline: 0px;
  outline-offset: -2px;
  background-color: #3d3d3d;
  border-bottom: 8px solid #1d76db;
}

li.bx--tabs__nav-item--selected {
  // color: white !important;
  
  border-bottom: 8px solid #1d76db !important;
  background-color: #3d3d3d !important;
}

li.bx--tabs__nav-item:hover {
  // color: white !important;
  border-bottom: 8px solid #1d76db !important;
  -webkit-transition : border-bottom 300ms ease-out;
  -moz-transition : border-bottom 300ms ease-out;
  -o-transition : border-bottom 300ms ease-out;
  transition : border-bottom 300ms ease-out;
  background-color: #3d3d3d !important;
}

li.bx--tabs__nav-item {
  // border-bottom: 3px solid #1d76db !important;
  // color: #565656;
  // color: white !important;
  // color: white;
  text-decoration: none;
  font-weight: 400;
  background-color: rgb(24, 22, 22);
}

.bx--tabs__nav-item + .bx--tabs__nav-item {
  margin-left: 0px; 
  border-left: 1px solid darkgray;
}

.labels {
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 70px;
  display: inline-block;
}

.bx--search--xl .bx--search-input {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0;
  height: 25px;
  padding: 0 0.5rem 0 3rem;
  outline: 1px solid black;
  background-color: white;
  color: #171717;
  width: 400px;
  border-bottom: 0px;
}

.bx--search--xl .bx--search-close {
  height: 2rem;
  width: 2rem;
  display: none;
}

.bx--search-close:active {
  // outline: 2px solid #0062ff;
  outline: 0px !important;
}

.bx--list-box__field {
  width: 150px;
  height: 25px;
  padding: 0 3rem 0 3rem;
  display: flex;
}

.bx--dropdown {
  display: inline-block;
  background-color: white;
  border: 1px solid;
  height: 25px;
}

.bx--dropdown:focus, .bx--dropdown:active {
  outline: 0px;
}

.bx--dropdown:hover {
  background-color: white;
}

.bx--list-box__menu-item:hover {
  background-color: #e5e5e5;
}

.bx--dropdown-item:hover {
  background-color: white;
  color: #000000;
}

.bx--list-box__menu-item {
  background-color: white;
}

.bx--list-box__menu-item__option {
  padding: 0.6875rem 0 0 2rem;
}

.bx--data-table th {
  color: #ffffff;
  background-color: black;
}

.bx--data-table td {
  background: #f4f7f9;
  color: #000000;
}

tr.bx--parent-row:not(.bx--expandable-row):first-of-type:hover td {
  border-top: 0px; 
  border-bottom: 0px; 
  border-color: transparent !important; 
}

tr.bx--parent-row .bx--expandable-row .bx--expandable-row--hover {
  border-top: 0px; 
  border-bottom: 0px; 
  border-color: transparent !important; 
}

tr.bx--parent-row.bx--expandable-row.bx--expandable-row--hover {
  border-top: 0px; 
  border-bottom: 0px; 
  border-color: transparent !important; 
}

.bx--parent-row .bx--expandable-row .bx--expandable-row--hover td {
  border-top: 0px; 
  border-bottom: 0px; 
  border-color: transparent !important; 
}

.bx--data-table tr {
  border-top: 0px !important; 
  border-bottom: 0px !important; 
  border-color: transparent !important; 
}

.bx--expandable-row:hover tr td {
  border-top: 0px; 
  border-bottom: 0px; 
  border-color: transparent !important; 
}

.bx--data-table tr.bx--parent-row:first-of-type td {
  border-top: 0px;
  border-bottom: 0px; 
}

tr.bx--parent-row.bx--expandable-row:hover td:first-of-type {
  border-top: 0px;
  border-bottom: 0px;
  border-color: transparent !important; 
}

.bx--data-table--zebra tbody tr:hover td {
  border-bottom: 0px;
  border-top: 0px;
  border-color: transparent !important; 
}

.bx--data-table--zebra tbody tr:nth-child(odd) td {
  background-color: #eef5ff !important;
  border-bottom: 0px; 
  border-top: 0px;
  border-color: transparent !important; 
}

.bx--data-table--zebra tbody tr:nth-child(even) td {
  background-color: white !important;
  border-bottom: 0px; 
  border-top: 0px;
  border-color: transparent !important; 
}

.bx--data-table--zebra tbody tr:nth-child(odd):hover td {
  background-color: #eef5ff !important;
  border-bottom: 0px; 
  border-top: 0px;
  border-color: transparent !important; 
}

.bx--data-table--zebra tbody tr:nth-child(even):hover td {
  background-color: white !important;
  border-bottom: 0px; 
  border-top: 0px;
  border-color: transparent !important; 
}

.bx--data-table tbody tr:hover td {
  color: #171717;
  background: transparent !important; 
  border-bottom: 0px; 
  border-color: transparent !important; 
  border-top: 0px;
}

tr {
  border-color: transparent !important; 
}
  
.bx--table-toolbar {
  background: #edf4ff;
}

body {
  // padding: 1rem 3%;
  background-color: #fff;
}

.resize {
  width: 17px;
  height: 17px;
  align-content: center;
}

.resize_running {
  width: 20px;
  height: 20px;
  align-content: center;
}

.title {
  font-size: 20px;
  color: blue;
  border-width: 2px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.main-title {
  font-size: 35px;
  color: white;
  border-width: 2px;
  // border-radius: 25px;
  background-image: url("resources/title_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px;
  height: 150px;
  display: flex;
  align-items: center;
}

.refresh_timestamp {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  color: steelblue;
  font-style: italic;
  font-weight: bold;
}

.timestamp {
  margin-top: 10px;
  margin-bottom: 10px;
  color: red;
  font-style: italic;
  font-weight: bold;
}

.compactStyles tbody p {
  font-size: 12px;
}

.noBorder td {
  border: 0;
}
.noBorder tr {
  border: 0;
}

.big-cell {
  padding: 0.75rem;
}

.noBorder .bx--data-table-v2 {
  border: 0 !important;
}

.bx--data-table-v2-container {
  overflow: visible;
}

.dashboard-container {
  display: -ms-flexbox;
  display: flex;
  display: inline-block;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;

  align-items: center;
}

.dashboard-boarder {
  border: 1px solid lightgrey;
  box-shadow: 2px 4px #f4f7fb;
  margin-top: 0.5rem;
  margin-right: 2rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}

.dashboard-section {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}

.dashboard-section text.barLabel {
  font-size: 12px;
  text-anchor: initial;
}
.dashboard-section g {
  font-size: 12px;
}

svg text.barLabel {
  font-size: 12px;
}

.dashboard-graph-table th {
  font-size: 12px;
  font-weight: bold;
  background-color: #fff4b3;
}

.dashboard-graph-table tbody tr {
  background-color: #f2f2f2;
  cursor: pointer;
}

.overlay {
  fill: #3d70b2;
  opacity: 0.1;
  display: none;
  pointer-events: none;
}

.line {
  stroke-width: 2;
  stroke: #ff00ff;
  fill: none;
  pointer-events: none;
}

.axis path {
  stroke: #5a6872;
}

.tick line {
  stroke: #5a6872;
}

.tick text {
  fill: #5a6872;
}

.bx--graph-header {
  font-weight: 300;
  font-size: 24px;
  text-align: center;
}

.graph-container {
  display: inline-block;
  position: relative;
}

.OLDgraph-container {
  position: relative;
}

.tooltip2 {
  font-weight: 700;
  padding-left: 1rem 2rem;
  background-color: #fff;
  position: absolute;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #dfe3e6;
  padding: 0.25rem 0.5rem;
  pointer-events: none;
  display: none;
}
.tooltip2:after {
  content: "";
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;

  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
}

.y path {
  display: none;
}

.label {
  font-size: 15px;
  font-weight: 700;
  fill: #5a6872;
  text-anchor: middle;
}

.HIDE-NOT-IMPLEMENTED {
  visibility: hidden;
  display: none;
}

.red--tag {
  background-color: #ffb3b3;
  color: #990000;
}

.blue--tag {
  background-color: #b3e6ff;
  color: #325c80;
}

.git--blue-light--tag {
  background-color: #c5def5;
  color: #000000;
}

.git--red--tag {
  background-color: #ee0701;
  color: #ffffff;
}

.git--orange-dark--tag {
  background-color: #d93f0b;
  color: #ffffff;
}

.git--yellow--tag {
  background-color: #fbca04;
  color: #000000;
}

.git--blue-medium--tag {
  background-color: #c5def5;
  color: #000000;
}

.git--blue-dark--tag {
  background-color: #1d76db;
  color: #ffffff;
}

.git--green--tag {
  background-color: #0e8a16;
  color: #ffffff;
}

.centerLabel {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.centerLabel p {
  text-align: center;
}

.centerLabel .amount {
  font-size: 29px;
  line-height: 1;
  font-weight: 300;
}

.centerLabel .item {
  font-weight: 400;
  font-size: 14px;
  color: #5a6872;
}

.bx--toolbar-content.no-margin {
  margin-left: 0;
}
.bx--toolbar-content {
  margin-right: 40px;
}
.toolbar-filter-container {
  display: flex;
  margin-left: auto;
}

.bx--dropdown-list {
  width: auto;
}
:focus {
  outline: none;
}
.bx--multi-selecter:focus {
  outline: none;
}

.toolbar-filter-container .bx--list-box__field {
  padding-right: 40px;
}

.toolbar-filter-container .title {
  margin: auto;
  font-size: 0.875rem;
}

.toolbar-filter-container .bx--multi-select .bx--dropdown__arrow {
  top: 0.8rem;
}

.chart .legend {
  fill: black;
  font: 14px sans-serif;
  text-anchor: start;
  font-size: 12px;
}

.chart text {
  fill: black;
  // #font: 10px sans-serif;
  // #text-anchor: end;
  font-size: 12px;
  text-anchor: initial;
}

.chart .label {
  fill: black;
  font: 14px sans-serif;
  text-anchor: end;
}

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.clickable {
  cursor: pointer;
}

.chart-view-section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  border: 1px solid blue;
  // box-shadow: 2px 4px #f4f7fb;
}

.selected {
  font-weight: 700;
}

.chart-view .dashboard-container {
  margin-bottom: 0;
}

.aligned-graph .dashboard-container {
  margin-bottom: 0;
  height: 100%;
}

.inline-dropdown.bx--dropdown--inline {
  display: inline-block;
}

.inline-dropdown.bx--dropdown--inline .bx--dropdown-text {
  padding-left: 0.2rem;
}

.timeseries {
  cursor: pointer;
}

.timeseries .tooltip-text {
  font-size: 1rem;
}

.timeseries .axis path,
.timeseries .axis line {
  fill: none;
  stroke: black;
  stroke-width: 1px;
  shape-rendering: crispEdges;
}

.timeseries .axis-title {
  fill: #5b6770;
  letter-spacing: 1px;
}

.timeseries .line {
  fill: none;
  stroke-width: 2px;
}

.timeseries .overlay {
  fill: none;
  pointer-events: all;
  display: block;
}

.timeseries .focus circle {
  stroke: black;
  fill: #f1f3f3;
  stroke-width: 2px;
}

.timeseries .hover-line {
  stroke: black;
  stroke-width: 2px;
  stroke-dasharray: 2, 4;
}

.timeseries .green-stroke {
  stroke: #2ca02c;
}

.timeseries .red-stroke {
  stroke: #990000;
}

.timeseries .orange-stroke {
  stroke: #ff8c00;
}

.timeseries .blue-stroke {
  stroke: blue;
}

.jobsList__container .bx--data-table-container {
  .bx--table-toolbar {
    justify-content: flex-end;
    padding-right: 1rem;
  }
  .bx--toolbar-search-container-active .bx--search .bx--search-input:focus {
    outline: none;
  }
}
